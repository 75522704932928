jQuery(document).ready(function ($) {
  // Resize and move elements
  function khoozhResponsiveElements() {
    // Desktop width
    if ($(document).width() < 960) {
      // $('#order-review-wrapper').insertBefore($('.woocommerce-checkout-payment').prev());
    } else {
      // if (typeof wooPinCheckoutReview === "function") {
      //   wooPinCheckoutReview();
      // }
      // $('#order-review-wrapper').appendTo($('#customer_details .col-2'));
    }
  }
  khoozhResponsiveElements();
  $(window).resize(khoozhResponsiveElements);

  // init controller
  var controller = new ScrollMagic.Controller();

  // Create scene for every slide
  // Fade In elements
  // var hiddenElements = $(".section-title, .product");
  // hiddenElements.addClass("hidden");
  // hiddenElements.each(function () {
  //   var myScene = new ScrollMagic.Scene({
  //     triggerElement: this,
  //     triggerHook: "onEnter",
  //     offset: 120,
  //   })
  //     .setClassToggle(this, "shown")
  //     .addTo(controller);
  // });

  // Fade Right elements
  // var fadeRight = $(".cols-2 > *:nth-child(odd)");
  // fadeRight.addClass("fade-right-before");
  // fadeRight.each(function () {
  //   var myScene = new ScrollMagic.Scene({
  //     triggerElement: this,
  //     triggerHook: "onEnter",
  //     offset: 120,
  //   })
  //     .setClassToggle(this, "fade-right")
  //     .addTo(controller);
  // });

  // Fade Left elements
  // var fadeLeft = $(".cols-2 > *:nth-child(even), .guarantee-section img");
  // fadeLeft.addClass("fade-left-before");
  // fadeLeft.each(function () {
  //   var myScene = new ScrollMagic.Scene({
  //     triggerElement: this,
  //     triggerHook: "onEnter",
  //     offset: 120,
  //   })
  //     .setClassToggle(this, "fade-left")
  //     .addTo(controller);
  // });

  // Fade Scale elements
  // var fadeScale = $(".feature-image__main");
  // fadeScale.addClass("fade-scale-before");
  // fadeScale.each(function () {
  //   var myScene = new ScrollMagic.Scene({
  //     triggerElement: this,
  //     triggerHook: "onEnter",
  //     offset: 300,
  //   })
  //     .setClassToggle(this, "fade-scale")
  //     .addTo(controller);
  // });

  var counterStartingFrom = {
      val: 0,
    },
    projectsCount = 233,
    photosCount = 53,
    graphicsCount = 28;

  var projectCounter = new TimelineMax();
  var projectCounter = projectCounter.add([
    TweenLite.to(counterStartingFrom, 1.5, {
      val: projectsCount,
      roundProps: "val",
      onUpdate: function () {
        if ($("#counter-1").length === 1) {
          document.getElementById("counter-1").innerHTML =
            counterStartingFrom.val;
        }
      },
    }),
    TweenLite.to(counterStartingFrom, 2, {
      val: photosCount,
      roundProps: "val",
      onUpdate: function () {
        if ($("#counter-2").length === 1) {
          document.getElementById("counter-2").innerHTML =
            counterStartingFrom.val;
        }
      },
    }),
    TweenLite.to(counterStartingFrom, 2, {
      val: graphicsCount,
      roundProps: "val",
      onUpdate: function () {
        if ($("#counter-3").length === 1) {
          document.getElementById("counter-3").innerHTML =
            counterStartingFrom.val;
        }
      },
    }),
  ]);
  var myScene = new ScrollMagic.Scene({
    triggerElement: ".figure-highlight-list",
    triggerHook: 0.9,
  })
    .setTween(projectCounter)
    .addTo(controller);

  // Header scroll
  function headerScroll() {
    if ($(document).scrollTop() > 20) {
      $(".site-header").addClass("header-scroll");
    } else {
      $(".site-header").removeClass("header-scroll");
    }
  }
  headerScroll();
  $(document).on("scroll", headerScroll);

  /*
   * Hero Slider
   */
  // $(".hero-slider").ready(function() {
  //   $('.hero-slider').css({
  //     'opacity': '1',
  //     'visibility': 'visible'
  //   });
  // });
});
